// context/AppStateContext.jsx
import { createContext } from "preact";
import { useContext, useState } from "preact/hooks";

// Create the context
const NavStateContext = createContext(null);

// Create a provider component
export function NavStateProvider({ children }) {
  const [nav, setNav] = useState(false);
  const [product, setProduct] = useState(false);

  const toggleProduct = (e) => {
    setProduct(!product);
  };
  const toggleNav = (e) => {
    setNav(!nav);
  };
  // Values and functions to share across components
  const value = {
    navState: nav,
    productState: product,
    toggleProduct,
    toggleNav,
  };

  return (
    <NavStateContext.Provider value={value}>
      {children}
    </NavStateContext.Provider>
  );
}

// Custom hook to use the context
export function useNavState() {
  const context = useContext(NavStateContext);
  if (context === null) {
    throw new Error("useAppState must be used within an AppStateProvider");
  }
  return context;
}
